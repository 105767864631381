




import Vue from 'vue'
export default Vue.extend({
    name: 'collapseRowButton',
    props: {modelData:{}},
    computed: {
        collapsed: {
            get():boolean{
                return (this.modelData as any).b[(this.modelData as any).p];
            },
            set(newVal:boolean){
                (this.modelData as any).b[(this.modelData as any).p] = newVal;
                // this.$set( (this.modelData as any).b, (this.modelData as any).p, newVal);
            },
            cache: false
        }
    }
})












































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Budget } from '@/common/classes/budget';
import { budgetDataFilters } from '@/common/unit-economy/budget/budget.filters';
import BudgetActivationMonthCell from '@/components/constructor-budget-tab/BudgetActivationMonthCell.vue';
export default Vue.extend({
    name: 'budgetEarnings',
    components: {
        BudgetActivationMonthCell
    },
    props: {
        showLabels: {type:Boolean},
    },
    computed: {
        groups60Month():number[]{
            return this.totalSales60Month.map((sales,month)=>this.budget.countGroupsForMonth(month, sales));
        },
        totalSales60Month(this:any):number[]{
            return this.$parent.totalSales60Month;
        },
        returningClients60Month():number[]{
            return this.budget.getMonthlyReturningClients(60);
        },
        timeRangeColumns(this:any):number{
            return this.showLabels ? 0 : this.$parent.timeRangeColumns;
        },
        budget(this:any):Budget{
            return this.$parent.budget;
        },
        wrapTableVal(this:any):any{
            return this.$parent.wrapTableVal;
        },
        col(this:any):any{
            return this.$parent.col;
        },
        ...mapGetters(['channels','product']),
    },
    directives: {
        tlabel: {
            inserted(el,_,vnode):void { 
                const ctx = vnode.context as any;
                if(!ctx.showLabels) el.style.display = 'none'; 
            },
        },
    },
    filters: {
        ...budgetDataFilters
    }
})

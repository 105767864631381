

































//TODO: (?) replace calls to $parent with mixin that is used in BudgetTab and here
import Vue from 'vue';
import CollapseRowButton from '@/components/constructor-budget-tab/CollapseRowButton.vue';
import { budgetDataFilters } from '@/common/unit-economy/budget/budget.filters';
import { Expense } from '@/common/classes/expenses';
import budget from '@/common/classes/budget';
import TaxUtils from '@/common/classes/tax.utils';
import { mapGetters } from 'vuex';
import { ProductTax } from '@/common/types/product.types';
import BudgetActivationMonthCell from './BudgetActivationMonthCell.vue';

export default Vue.extend({
    name: 'budgetExpenseRow',
    components: {CollapseRowButton, BudgetActivationMonthCell},
    props: {
        type: {},
        expIndex: Number,
        showLabels: {type:Boolean}
    },
    methods: {
        totalCostForCell(i:number){
            const {wrapTableVal} = (this as any).$parent;
            // const sumArr = (arr:number[])=>arr.reduce((acc,cur)=>acc+cur,0);
            const expenses = Object.values( this.uuidMonthlyExpenseCost );
            return wrapTableVal((mi:number)=>{
                let ret = expenses.reduce((acc,cur)=>acc + cur[mi], 0);
                if(TaxUtils.isExpenseTypeRelatedToCurrentTax(this.expIndex)) ret += budget.getTaxCostForMonth(mi, this.parentTotalSales[mi]);
                return ret;
            }, i);
        },
        getTimeRangeColumns(type:'tax'|undefined){
            const parentTimeRangeColumns = (this.$parent as any).timeRangeColumns;
            if(this.showLabels) return 0;
            if(type == 'tax') return parentTimeRangeColumns;
            return /* this.expIndex == 2 ? 1 : */ parentTimeRangeColumns;
        }
    },
    directives: {
        tlabel: {
            inserted(el,_,vnode):void { 
                const ctx = vnode.context as any;
                if(!ctx.showLabels) el.style.display = 'none'; 
            },
        },
    },
    computed: {
        showCollapseButton():boolean{
            const type:any = this.type;
            return type.exp.length || TaxUtils.isExpenseTypeRelatedToCurrentTax(this.expIndex);
        },
        curTax():ProductTax{
            return TaxUtils.getCurrentTax();
        },
        //FIXME: Invalid VS
        typeExpenses():Expense[]{
            return (this.type as any).exp as Expense[];
        },
        parentTotalSales():number[]{
            return (this.$parent as any).totalSales60Month;
        },
        uuidMonthlyExpenseCost():Record<string,number[]>{
            const uuidSalesMap:Record<string,number[]> = {};
            const sales = this.parentTotalSales;
            this.typeExpenses.forEach((e)=> uuidSalesMap[e.uuid] = budget.getMonthlyExpenseCost(e,60,sales) )
            return uuidSalesMap;
        },
        isOnetime():boolean{
            if(!this.typeExpenses.length) return false;
            return this.typeExpenses[0].type == 2;
        },
        isCollapsed(this:any):boolean{
            return this.$parent.col[this.type.col];
        },
        taxMonthlyCost():number[]{
            return budget.getMonthlyTaxCost(60, this.parentTotalSales);
        },
        showTax():boolean{
            return TaxUtils.isExpenseTypeRelatedToCurrentTax( this.expIndex );
        },
        ...mapGetters(['product'])
    },
    filters: {
        ...budgetDataFilters
    }
})

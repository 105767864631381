





































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { budgetDataFilters } from '@/common/unit-economy/budget/budget.filters';
import budget from '@/common/classes/budget';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import CollapseRowButton from '@/components/constructor-budget-tab/CollapseRowButton.vue';
import BudgetExpenseRow from '@/components/constructor-budget-tab/BudgetExpenseRow.vue';
import BudgetChannelRow from './BudgetChannelRow.vue';
import BudgetEarnings from './BudgetEarnings.vue';

export default Vue.extend({
    name: 'budgetTab',
    props: {disableRender:{type:Boolean,default:false}},
    mounted(){
        this.showTable = this.budgetIsCreated && window.getComputedStyle(this.$el).display != 'none';
        // setTimeout(()=>console.log( {corrected:this.budget.getMonthlyReturningClients(30)} ),1000)
    },
    mixins: [MiscMixin],
    components: { 
        // SmartNumberInput,
        // CollapseRowButton,
        BudgetExpenseRow,
        BudgetChannelRow,
        CollapseRowButton,
        BudgetEarnings,
    },
    data(){
        //Collapse these rows
        const col = {
            unit: true,
            const: true,
            onetime: true,
            channelExp: true,
            earnings: true,
        };
        return {
            col,
            showTable: false,
            budget,
            curChart: 0,
            isBudgetInYears: false,
        };
    },
    watch: {
        col:{
            handler(newVal):void{
                this.$emit('update-constructor-container-height');
            },
            deep: true
        },
        disableRender:{ 
            async handler(newVal:boolean):Promise<void>{
                if(newVal) {
                    this.isBudgetInYears = false;
                    await new Promise((r)=>setTimeout(r, 100));
                    this.showTable = true;
                    this.$forceUpdate();
                }
                else{ 
                    this.showTable = false; 
                }
            }
        },
        product: {
            handler(){
                this.$forceUpdate();
            },
            deep: true
        },
        channels: {
            handler(){
                this.$forceUpdate();
            },
            deep: true
        },
    },
    methods: {
        //Called from UnitEconomyConstructor
        setIsBudgetInYears(isChecked:boolean):void{
            this.isBudgetInYears = isChecked;
        },
        debugWrapTableVal(...data:any):number{
            return (budget as any).countBudgetVal(...data,this.isBudgetInYears,true);
        },
        wrapTableVal(...data:any):number{
            if(!this.isBudgetInYears) return data[0](data[1]-1);
            return (budget as any).countBudgetVal(...data,this.isBudgetInYears);
        },
    },
    computed: {
        earnings60Month():number[]{
            return budget.getMonthlyEarnings(60, this.totalSales60Month);
        },
        expenses60Month():number[]{
            return budget.getMonthlyExpenses(60, this.totalSales60Month);
        },
        //Called from BudgetEarnings
        totalSales60Month():number[]{
            return budget.getAllMonthlySales(60);
        },
        moneyFlow60Month():number[]{
            return budget.getMoneyFlow(60, this.totalSales60Month);
        },
        rowTypes(){
            return [
                {name:'переменные',col:'unit',exp:budget.getUnitExpenses()},
                {name:'постоянные',col:'const',exp:budget.getConstExpenses()},
                {name:'разовые',col:'onetime',exp:budget.getOnetimeExpenses()},
            ];
        },
        timeRangeColumns():number{
            return this.isBudgetInYears ? 5 : 60;
        },
        accMoneyFlowFor60Months():number[]{
            return budget.getAccMoneyFlow(60);
        },
        budgetIsCreated():boolean{
            return budget.getProduct() && !! budget.getChannels();
        },
        ...mapGetters(['product','channels'])
    },
    filters: {
        ...budgetDataFilters,
    }
})































import Vue from 'vue';
import { mapGetters } from 'vuex';
import CollapseRowButton from '@/components/constructor-budget-tab/CollapseRowButton.vue';
import { ChannelModel } from '@/common/classes/models';
import { budgetDataFilters } from '@/common/unit-economy/budget/budget.filters';
import budget from '@/common/classes/budget';
import BudgetActivationMonthCell from '@/components/constructor-budget-tab/BudgetActivationMonthCell.vue';

export default Vue.extend({
    name: 'budgetChannelRow',
    components: {
        CollapseRowButton, 
        BudgetActivationMonthCell
    },
    mounted(){
        const col = (this.$parent as any).col; 
        if(col[this.colKey] == undefined) this.$set( col, this.colKey, true );
    },
    data(){
        return{
            colKey: this.channel.uuid
        }
    },
    methods: {
        marketingExpensesForCell(i:number){
            const {wrapTableVal,budget} = this.$parent as any;
            return wrapTableVal((mi:number)=> budget.channelMarketingExpenseForMonth(mi,this.channel),i);
        }
    },
    props: {
        channel: {type: ChannelModel},
        showLabels: {type: Boolean}
    },
    filters: {
        ...budgetDataFilters
    },
    directives: {
        tlabel: {
            inserted(el,_,vnode):void { 
                const ctx = vnode.context as any;
                if(!ctx.showLabels) el.style.display = 'none'; 
            },
        },
    },
    computed: {
        multiplePaidChannels():boolean{
            return budget.allPaidChannels().length > 1;
        },
        timeRangeColumns(this:any):number{
            return this.showLabels ? 0 : this.$parent.timeRangeColumns;
        },
        isCollapsed():boolean{
            return (this.$parent as any).col[this.colKey];
        },
        ...mapGetters(['channels','renderData','isLoadingProject'])
    }    
})

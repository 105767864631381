






import Vue from 'vue'
export default Vue.extend({
    name: 'budgetActivationMonthCell',
    props: {
        modelData: {type:Object},
        defaultValue: {type:Number}
    },
    mounted(){
        if(this.modelData.b[this.modelData.p] == undefined)
            this.$set( this.modelData.b, this.modelData.p, this.defaultValue );
    },
    methods: {
        onChange(v:any){
            if(v == undefined) {
                this.$set( this.modelData.b, this.modelData.p, this.defaultValue );
                // (this.$refs.smartInput as any).target.value = this.defaultValue;
            }
        },
    },
    computed: {
        value():number{
            return this.modelData.b[this.modelData.p];
        },
        showCell():boolean{
            return !(this.$parent as any).showLabels;
        },
    },
})
